<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top plc-banner-container xl:pt-4 2xl:pt-8 w-full">
            <div class="relative h-full w-full flex justify-center flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-12" src="../../../assets/images/home-page/plc-perks/plc-banner.png" alt="PLC Perks Banner">
                <div class="flex flex-col -mb-6 mobileL:mb-0 z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 text-start mobileL:text-center ml-0 lg:ml-16 2xl:ml-20">
                    <h1 class="plc-banner-title flex mobileL:static mobileL:justify-center ml-6 mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        P.L.C. <span class="hidden mobileL:inline">Perks</span>
                    </h1>
                    <p class="mobileL:mt-4 text-sm sm:text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl ml-2 px-4 mr-6 mobileL:mr-0 text-white-1">
                        We want to make you feel extra special with the VIP 
                        treatment you deserve. (It's really our way of thanking you for 
                        your trust and having confidence in us through and through.)
                    </p>
                </div>
            </div>
        </div>

        <div class="relative">
            <img class="w-full h-full absolute object-cover" src="../../../assets/images/home-page/plc-perks/update-bg.png" alt="">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col mobileL:py-4 py-2">
                <h2 class="relative update-title font-tahu flex text-4xl md:text-5xl lg:text-8xl 2xl:text-10xl">
                    Update
                </h2>
                <p class="text-white-1 text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl mobileL:w-3/5 text-center mx-6 mobileL:mx-0">
                    PLC application is currently on hold. Stay tuned for further announcements 
                    on our social media channels. For other PLC concerns, please visit the 
                    Contact Us tab or send an email to
                    <span><a class="underline" href="mailto:customercare@lay-bare.com">
                    customercare@lay-bare.com
                    </a></span>
                </p>
            </div>
        </div>

        <div class="relative">
            <img class="h-full w-full absolute" src="../../../assets/images/home-page/plc-perks/body-background.png" alt="">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col text-brown-4 pt-8">
                <p class="text-center font-semibold md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl mobileL:w-4/6 mx-6 mobileL:mx-0"> 
                    The Premier Loyalty Card (PLC) is our rewards program that lets our
                    most loyal clients enjoy amazing perks from our merchants partners
                    and a 10% discount on all our services for life. (And yes, we mean
                    it, FOR THE REST OF YOUR LIFE.)
                </p>
                <p class="text-center text-sm lg:text-lg xl:text-xl 2xl:text-2xl mt-2 lg:mt-12 mx-8 ">
                    Also in partnership with for exclusive freebies, discounts and more!
                </p>
                <div class="mx-auto px-5 pb-8">
                    <div class="mt-2 lg:mt-12 flex justify-between flex-wrap gap-4 md:gap-8 lg:gap-20 2xl:gap-24">
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/azumi.png" alt="Azumi"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/azta.png" alt="Azta"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/dessert-museum.png" alt="Dessert Museum"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/enchanted-kingdom.png" alt="Enchanted Kingdom"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/inflatable-island.png" alt="Inflatable Island"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-brown-4 py-8 lg:py-12 text-white-1">
            <div class="text-center mb-4">
                <h2 class="text-4xl md:text-6xl lg:text-7xl 2xl:text-9xl font-tahu mb-6">How to get one?</h2>
            </div>
            <div class="flex-row">
                <div class="mx-auto w-5/6 2xl:gap-20 md:gap-12 flex flex-col mobileL:flex-row md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">
                    <div class="flex-1 text-center flex flex-col items-center mb-6">
                        <img class="w-8 mobileL:w-14 mb-4" src="../../../assets/images/home-page/plc-perks/1.png" alt="1">
                        <p class="">Download and Register in our app!</p>
                    </div>
                    <div class="flex-1 text-center flex flex-col items-center mb-6">
                        <img class="w-8 mobileL:w-14 mb-4" src="../../../assets/images/home-page/plc-perks/2.png" alt="2">
                        <p class="">You must spend an accumulated amount worth Php 5,000.00 
                            on any of our services through booking made in the app
                        </p>
                    </div>
                    <div class="flex-1 text-center flex flex-col items-center mb-6">
                        <img class="w-8 mobileL:w-14 mb-4" src="../../../assets/images/home-page/plc-perks/3.png" alt="3">
                        <p class="">Your profile will automatically tagged as a PLC holder. No need to apply!</p>
                    </div>
                </div>
                <div class="2xl:mt-16">
                    <div class="flex justify-center items-center gap-4 flex-col mobileL:flex-row">
                        <a href="https://apps.apple.com/ph/app/lay-bare/id1492384941" target="_blank">
                            <img class="w-36 2xl:w-44" src="../../../assets/images/home-page/plc-perks/apple.png" alt="App store" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.lbo.mobile.laybare&hl=en&gl=US" target="_blank">
                            <img class="w-36 2xl:w-44" src="../../../assets/images/home-page/plc-perks/google-play.png" alt="Google playstore"/>
                        </a>
                        <a href="https://appgallery.huawei.com/#/app/C103688251" target="_blank">
                            <img class="w-36 2xl:w-44" src="../../../assets/images/home-page/plc-perks/huawei.png" alt="Huawei"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybareFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/laybare/plc-perks')
    },
}
</script>

<style>

.plc-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .plc-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .plc-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .plc-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.plc-banner-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #C58965;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

.update-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #6DB93D;
    font-style: normal;
    font-weight: 400;
}


@media only screen and (min-width: 768px) {
    .plc-banner-title {
        text-shadow: 0px 6px 0px #C58965;
    }
    .update-title {
        text-shadow: 0px 6px 0px #6DB93D;
    }
}

@media only screen and (min-width: 992px) {
    .plc-banner-title {
        text-shadow: 0px 8px 0px #C58965;
    }
    .update-title {
        text-shadow: 0px 8px 0px #6DB93D;
    }
}

@media only screen and (min-width: 1200px) {
    .plc-banner-title {
        text-shadow: 0px 10px 0px #C58965;
    }
    .update-title {
        text-shadow: 0px 10px 0px #6DB93D;
    }
}

.banner-faq {
    background: url('../../../assets/images/home-page/plc-perks/banner-faqs.jpg')
    no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
